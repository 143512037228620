<template>
  <div>
    <div v-if="this.$route.name === 'Other'">
      <Other />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Other from "@/views/companyApp/other/Other.vue";

export default {
  name: 'OtherParent',
  components: {
    Other
  }
}
</script>
