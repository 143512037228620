var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container--fluid grid-list-md"},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-medium text-body-1 primary--text text--darken-1"},[_vm._v(_vm._s(_vm.$t("other.otherList")))]),(_vm.accessRight.includes('create'))?_c('CommonAdd',{attrs:{"name":_vm.$t('other.other'),"pathName":"other/addOther"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[(_vm.listSkeleton)?_c('ListSkeleton'):(_vm.otherList.data)?_c('v-card-text',{staticClass:"text-h5 pa-0"},[_c('v-data-table',{staticClass:"custom-table mb-0 ma-2 row-pointer",staticStyle:{"word-break":"break-word"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.otherList.data,"page":_vm.page,"items-per-page":_vm.limit,"options":_vm.pagination,"server-items-length":_vm.otherList.meta.total,"footer-props":{
              'items-per-page-options': [10, 20, 30, 40, 50],
            },"item-class":"py-4","search":_vm.search,"sort-by":_vm.sortByValue,"sort-desc":_vm.sortDescValue},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.pagination=$event},"dblclick:row":_vm.redirectOnEditPage,"update:sortBy":function($event){_vm.sortByValue=$event},"update:sort-by":[function($event){_vm.sortByValue=$event},function (val) { return _vm.buildUrl(val, 'sortKey'); }],"update:sortDesc":function($event){_vm.sortDescValue=$event},"update:sort-desc":[function($event){_vm.sortDescValue=$event},function (val) { return _vm.buildUrl(val, 'sortDec'); }]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"text-body-2 primary--text mb-2"},[_c('v-row',{attrs:{"dense":true}},[_c('v-col',{attrs:{"cols":"10"}}),_c('v-col',{staticClass:"text-end d-flex",attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"full-width":false,"dense":"","hide-details":"auto","append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":""},on:{"input":_vm.buildUrl},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.gross_profit",fn:function(props){return [_c('v-checkbox',{staticStyle:{"height":"inherit"},attrs:{"dense":"","readonly":"","color":"primary","input-value":props.item.gross_profit ? true : false}})]}},{key:"item.actions",fn:function(props){return [(
                  _vm.accessRight.includes('edit') ||
                  _vm.accessRight.includes('delete')
                )?_c('v-menu',{attrs:{"offset-x":"","left":"","content-class":"elevation-2","flat":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(_vm.accessRight.includes('edit'))?_c('v-list-item',{staticClass:"primary--text",attrs:{"dense":"","to":{
                      path: 'other/otherAction/' + props.item.id,
                    }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.edit")))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v("mdi-circle-edit-outline")])],1)],1):_vm._e(),(_vm.accessRight.includes('delete'))?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){(_vm.confirmationDialog = true), (_vm.delete_item = props.item)}}},[_c('v-list-item-content',{staticClass:"error--text"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.delete")))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"error--text",attrs:{"small":""}},[_vm._v("mdi-delete-sweep-outline")])],1)],1):_vm._e()],1)],1):_vm._e()]}},{key:"footer.prepend",fn:function(){return [_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12"}},[_c('span',{staticClass:"pl-3 pt-2"},[_vm._v(_vm._s(_vm.$t("common.numberOfTotalRows"))+" "+_vm._s(_vm.otherList.meta.total))])])],1)]},proxy:true},{key:"footer.page-text",fn:function(){return [_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"mx-auto mr-16",attrs:{"cols":"4"}},[_c('v-container',{staticClass:"w-100"},[_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.otherList.meta.last_page},on:{"input":_vm.buildUrl},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)]},proxy:true}],null,true)})],1):_c('v-card-text',{staticClass:"text-caption text-center"},[_vm._v(" "+_vm._s(_vm.$t("common.noDataFound")))])],1)],1),_c('confirmation-dialog',{attrs:{"dialog":_vm.confirmationDialog,"delete_item":_vm.delete_item},on:{"update:dialog":function($event){_vm.confirmationDialog=$event},"update:delete_item":function($event){_vm.delete_item=$event},"deleteItem":_vm.deleteItem}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }